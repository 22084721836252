import textFit from 'textfit';

let resizeTimeout: any;

const updateFontSize = () => {
  textFit(document.querySelectorAll('.textfit'), {
    multiline: true,
    maxFontSize: 999,
  });
};
const handleResize = () => {
  clearTimeout(resizeTimeout);
  resizeTimeout = setTimeout(() => {
    updateFontSize();
  }, 100);
};

export { updateFontSize, handleResize };
