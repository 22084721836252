import VueCompositionApi, { onMounted } from '@vue/composition-api';
import { updateFontSize, handleResize } from './utils';

// App main
const main = async () => {
  // Async load the vue module
  const { default: Vue } = await import(
    /* webpackMode: "lazy", webpackChunkName: "vue" */ 'vue'
  );
  const { default: lazyLoadComponent } = await import(
    /* webpackMode: "lazy", webpackChunkName: "lazy-load-component" */ '../utils/lazy-load-component'
  );

  Vue.config.productionTip = false;

  Vue.use(VueCompositionApi);

  // Create our vue instance
  const vm = new Vue({
    el: '#page-container',

    components: {
      SwiperComponent: () => import('./components/SwiperComponent.vue'),
    },

    setup() {
      onMounted(() => {
        updateFontSize();

        window.addEventListener('resize', handleResize);
      });

      const acceptCookies = () => {
        return new Promise((resolve, reject) => {
          fetch(
            `/actions/site-module/default/update-settings?cookiesAccepted=true`,
          )
            .then(response => {
              if (response.ok) {
                const cookieBanner = document.getElementById('cookieBanner');
                document.documentElement.classList.remove(
                  'cookies-not-accepted',
                );

                if (cookieBanner && cookieBanner.parentNode) {
                  cookieBanner.parentNode.removeChild(cookieBanner);
                }
                resolve();
              }
              reject(response);
            })
            .catch(error => {
              reject(error);
            });
        });
      };

      return { acceptCookies };
    },
  });

  return vm;
};

// Execute async function
main().then(vm => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
